.navbar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.navbar button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.navbar button:hover {
  background-color: #ddd;
}

.QRCodeForm {
  text-align: center;
  margin-bottom: 20px;
}

.QRCodeForm input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 500px;
}

.QRCodeDisplay {
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.QRCodeDisplay button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 40px;
}

.color-picker {
  margin-top: 20px;
  text-align: center;
}

.color-picker label {
  font-size: 16px;
  margin-right: 10px;
}

.color-picker input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
}
h1 {
  text-align: center;
  margin: 40px;
}
